@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

body {
  margin: 0;
  background-color: #fff;
  
  color: #242424;
  font-family: 'Noto Sans KR', sans-serif;
  font-feature-settings: 'clig' off, 'liga' off;
  font-style: normal;
  line-height: normal;
}

input * {
  font-family: 'Noto Sans KR';
}

input.upload {
  color: #242424 !important;
}

input.upload:disabled {
  color: #242424 !important;
}